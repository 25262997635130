<template>
    <div>

        <div class="card" id="calculation">
            <h3 class="card_title">Kalkulation</h3>
            <div class="padding">
                <div id="contentGrid" class="indent box">

                    <p><b>Losgröße:</b></p>
                    <input type="number" min="1" :value="information.lotsize" @input="changeValue('lotsize', $event.target.value)">

                    <p><b>Material</b></p><p></p>

                    <p class="indent">Materialname:</p>
                    <p>{{dict(material.name)}}</p>

                    <p class="indent">Materialtyp:</p>
                    <p>{{dict(material.type)}}</p>

                    <p class="indent">{{dict("price_"+material.type)}}:</p>
                    <p>{{material["price_"+material.type]}}€</p>

                    <p class="indent">Bedarf:</p>
                    <p>{{calcInfo.material.demand}}</p>

                    <p class="indent">Materialkosten:</p>
                    <p>{{calcInfo.material.cost}}€</p>

                    <p><b>Fertigungskosten</b></p><p></p>
                    <p class="indent">Maschine:</p>
                    <p>{{calcInfo.machines.name}}</p>

                    <p class="indent">Stundensatz:</p>
                    <p>{{calcInfo.machines.machinecost_hourly}}€/h</p>

                    <p class="indent">Fertigungsdauer:</p>
                    <p>{{calcInfo.manufacturing.time}}h</p>

                    <p class="indent">Fertigungskosten:</p>
                    <p>{{calcInfo.manufacturing.cost}}€</p>

                    <p class="indent">Rüstzeit:</p>
                    <p>{{calcInfo.setup.time}}min</p>

                    <p class="indent">Rüstkosten:</p>
                    <p>{{calcInfo.setup.cost}}€</p>

                    <p class="indent">Werkzeugkosten:</p>
                    <p>{{calcInfo.toolcost}}€</p>

                    <p><b>Sondereinzelkosten:</b></p>
                    <p>{{calcInfo.other.individual}}€/Stück</p>

                    <p class="textspacing"><b>Sondergemeinkosten:</b></p>
                    <p>{{calcInfo.other.overhead}}€</p>

                    <p><b>Herstellkosten:</b></p>
                    <p>{{calcInfo.cost.manufacturing}}€</p>                    

                    <p><b>Gewinn:</b></p>
                    <p>{{calcInfo.other.margin}}%</p>
                    <p><b>Rabatt:</b></p>
                    <p>{{calcInfo.other.discount}}%</p>
                    <p class="textspacing"><b>Skonto:</b></p>
                    <p>{{calcInfo.other.cashdiscount}}%</p>
                    <p></p><p></p>
                    <p><b>Gesamtpreis:</b></p>
                    <p>{{calcInfo.cost.total}}€</p>
                    <p><b>Stückpreis:</b></p>
                    <p>{{calcInfo.cost.piece}}€/Stück</p>

                </div>
                <div class="box">
                    <p @click="$store.commit('exportPDF', 'time')" class="padding_small pointer radius blue indent fitContent">Exportieren</p>
                </div>
            </div>
        </div>

        <div class="card" id="manufacturingTime">
            <h3 class="card_title">
                <indicator :condition="!verifiedSteps.processes" :ttle="verifiedSteps.processes" :required="1" :forcegray="!(opened.processes || verifiedSteps.processes)"></indicator>
                Fertigungszeit
            </h3>
            <div class="padding">
                <!-- <p class="textspacing">{{verifiedSteps.processes}}</p> -->
                <div id="timeGrid" class="indent box">
                    <div>
                        <p><b>Prozess</b></p>
                        <p><b>Zeit</b></p>
                        <p><b>Korrektur</b></p>
                    </div>
                    <div v-for="(t, i) in times" :key="i">
                        <p class="indent">{{dict(t.type)}}:</p>
                        <p>{{$store.getters.convertTime(t.time)}}</p>
                        <div v-if="t.type != 'toolchange'">
                            <p v-if="getCorrectedTime(t.type) >= 0">+</p>
                            <p v-else></p>
                            <input :class="{plus: getCorrectedTime(t.type)>=0}" type="number" step="5" min=-95 :value="getCorrectedTime(t.type)" @input="correctTime(t.type, $event.target.value)">
                            <p>%</p>
                        </div>
                    </div>
                    <div>
                        <p class="indent"><b>Gesamt:</b></p>
                        <p><b>{{$store.getters.convertTime($store.getters.time.toFixed(0))}}</b></p>

                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <h3 class="card_title">PDF Export</h3>
            <div class="padding">
                <div class="box">
                    <p><b>Kunde:</b></p>
                    <div class="exportGrid indent">
                        <p>Name:</p>
                        <input type="text" :value="customerInformation.name" @input="changeCustomerInformation('name', $event.target.value)">

                        <p>Straße:</p>
                        <input type="text" :value="customerInformation.street" @input="changeCustomerInformation('street', $event.target.value)">

                        <p>Hausnummer:</p>
                        <input type="text" :value="customerInformation.housenumber" @input="changeCustomerInformation('housenumber', $event.target.value)">

                        <p>Plz, Ort:</p>
                        <input type="text" :value="customerInformation.zipcodecity" @input="changeCustomerInformation('zipcodecity', $event.target.value)">
                    </div>
                </div>
                <div class="box">
                    <p><b>Preise nach Losgrößen:</b></p>
                    <div id="lotsizes" class="indent lotsizeGrid" v-for="(lotsize, l) in lotsizes" :key="l">
                        <input :id="'lotsize_'+l" style="width: 5em" type="number" :value="lotsize" @input="changeLotsize(l, $event.target.value)">
                        <input type="number" :value="lotsize_prices[lotsize]" @input="changeLotsizePrice(lotsize, $event.target.value)">
                        <p>€</p>
                    </div>
                    <div class="lotsizeGrid indent" v-if="lotsizes.length < 10">
                        <input placeholder="+ Neu" style="width: 5em" type="number" :value="newValue" @input="newLotsize($event.target.value)">
                        <p></p>
                    </div>
                </div>
                <div class="box">
                    <p><b>Weitere Informationen:</b></p>
                    <div class="lotsizeGrid indent">
                        <p>Gültigkeitsdauer:</p>
                        <input type="date" :value="customerInformation.offerValidity" @input="changeCustomerInformation('offerValidity', $event.target.value)">
                        <p></p>
                        <!-- 
                        <p>Rabatt:</p>
                        <input type="number" :value="other.discount" @input="changeOtherValue('discount', $event.target.value)">
                        <p>%</p> -->
                        
                        <p>Skonto:</p>
                        <input type="number" :value="other.cashdiscount" @input="changeOtherValue('cashdiscount', $event.target.value)">
                        <p>%</p>
                        <p class="indent" v-if="other.cashdiscount != 0">Skontofrist:</p>
                        <input v-if="other.cashdiscount != 0" type="number" :value="other.cashdiscount_days" @input="changeOtherValue('cashdiscount_days', $event.target.value)">
                        <p v-if="other.cashdiscount != 0">Tage</p>
                    </div>
                </div>
                <div class="box">
                    <p><b>Text:</b></p>
                    <div id="textBox" class="indent">
                        <p>Anschreiben:</p>
                        <textarea :value="customerInformation.lettertext" @input="changeCustomerInformation('lettertext', $event.target.value)" rows="4"></textarea>
                        <p>Schlusstext:</p>
                        <textarea :value="customerInformation.summarytext" @input="changeCustomerInformation('summarytext', $event.target.value)" rows="4"></textarea>
                    </div>
                </div>
                <p v-if="export_message" style="">Folgende Informationen werden noch benötigt:<br> {{export_message}}</p>
                <div class="box">
                    <p class="padding_small pointer radius blue indent fitContent" @click="$store.commit('exportPDF', exportType)">
                        Exportieren
                    </p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "Home",
    data() {
        return {
            lotsize_prices: {},
            // lotsizes: [1,50,250],
            customLotsizes: {},
            newValue: "",
            exportType: "offer"
        }
    },
    mounted() {
        this.refreshLotsizes()
    },
    methods: {
        dict(c) {
            return this.$store.state.dict[c] || c
        },
        changeValue(key, value) {
            this.$store.commit("information_set", [key, value])
            this.$store.dispatch("calculate")
        },
        correctTime(type, value) {
            if(value < -95) value = -95
            this.$store.commit("time_correct", [type, value])
            this.$store.dispatch("calculate")
        },
        getCorrectedTime(type) {
            return this.$store.getters.times_corrected[type] || 0
        },
        addOwnData() {
            this.$store.state.calculation.information.ownData.push({name: "", value:""})
        },
        removeOwnData(i) {
            this.$store.state.calculation.information.ownData.splice(i,1)
        },
        //  Export
        changeOtherValue(key, value) {
            this.$store.commit("setOtherValue", [key, value]);
            this.$store.dispatch("refresh")
            this.refreshLotsizes()
        },
        changeLotsize(i, value) {
            this.$store.commit("lotsize_change", [i, value]);
            this.refreshLotsizes()
        },
        changeLotsizePrice(lotsize, price) {
            this.customLotsizes[lotsize] = 1
            this.lotsize_prices[lotsize] = price
        },
        newLotsize(value) {
            this.$store.commit("lotsize_add", parseInt(value));
            // this.lotsizes.push(value)
            setTimeout(() => {
                document.getElementById("lotsize_"+(this.lotsizes.length-1)).focus()
            }, 50);
            this.newValue = ""
            this.refreshLotsizes()
            this.$forceUpdate();
        },
        refreshLotsizes() {
            let originalLotsize = this.information.lotsize
            this.lotsizes.forEach(lotsize => {
                if(this.customLotsizes[lotsize]) return
                this.$store.commit("information_set", ["lotsize", lotsize])
                this.$store.dispatch("calculate")
                this.lotsize_prices[lotsize] = this.$store.getters.calcInfo.cost.piece
            });
            this.$store.state.lotsize_prices = this.lotsize_prices
            this.$store.commit("information_set", ["lotsize", originalLotsize])
            this.$store.dispatch("calculate")
        },
        changeCustomerInformation(key, value) {
            this.$store.commit("setCustomerInformation", [this.$store.state, key, value])
        }
    },
    computed: {
        verify() {
            return this.$store.getters.verify
        },
        verifiedSteps() {
            return this.$store.getters.verifiedSteps
        },
        opened() {
            return this.$store.getters.verifyOpened
        },        
        
        information() {
            return this.$store.state.calculation.information
        },
        material() {
            return this.$store.state.calculation.material
        },
        times() {
            let times = this.$store.getters.times
            times.forEach((sP) => {
                sP.time = sP.time.toFixed(0)
            });
            return times
        },
        times_corrected() {
            return this.$store.getters.times_corrected
        },
        price() {
            if (typeof this.$store.state.price == "number") {
                return this.$store.state.price.toFixed(2)+"€"
            }
            else return this.$store.state.price
        },
        calcInfo() {
            this.$store.dispatch("calculate")
            return this.$store.getters.calcInfo
        },
        //  Export 
        lotsizes() {
            return this.$store.getters.lotsizes
        },
        customerInformation() {
            return this.$store.getters.customerInformation
        },
        other() {
            let values = this.$store.state.calculation.other
            if(!values.individual)values.individual = 0
            if(!values.overhead)values.overhead = 0
            if(!values.discount)values.discount = 0
            if(!values.cashdiscount)values.cashdiscount = 0
            return values
        },
        export_message() {
            return this.$store.getters.export_message
        }
    }
}
</script>

<style scoped>
    #progress{
        max-width: 50%;
    }

    #timeGrid>div{
        display: grid;
        grid-template-columns: 1fr 6em 6em;
        margin-bottom: 4px;
        grid-column-gap: 2em;
    }
    #timeGrid>div>div{
        display: grid;
        grid-template-columns: .5em 3em 1em;
    }
    #timeGrid>div>*:nth-child(2) {text-align-last: right;}
    #timeGrid>div>div>input{width: 2.5em;}
    #contentGrid{
        display: grid;
        grid-template-columns: auto 1fr;
        grid-column-gap: 1em;
        grid-row-gap: 8px;
    }

    #processes>div{margin-bottom: 4px;}
    #processes>div:nth-child(odd){
        background-color: rgb(241, 241, 241);
    }    
    #processes>div:nth-child(even){
        background-color: rgb(247, 247, 247);
    }
    /* #processes>div{
        display: flex;
    }
    #processes>div>p{
        margin: auto 0;
        height: fit-content;
    } */
    /* Export */
    #dataGrid{
        margin-bottom: 4px;
    }
    .exportGrid{
        display: grid;
        grid-gap: 4px 0em;
        grid-template-columns: 12em 16em;
    }
    .lotsizeGrid{
        display: grid;
        grid-gap: 4px 0em;
        grid-template-columns: 12em 8em 1em;
        }
    #lotsizes{
        margin-bottom: 4px;
        grid-template-columns: 12em 5em 1em auto;        
    }
    #lotsizeGrid{
        grid-template-columns: auto auto 2em!important;
        grid-column-gap: .5em!important;
        margin-bottom: 4px;
    }
    #textBox>textarea{
        width: calc(100% - 6px);
        resize: vertical;
    }
</style>